import React, { useState } from 'react';

const Contact = () => {
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setFeedbackMessage('');

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LdO2zcpAAAAAG-CBSf9CbCcw1vDenZTvciAcjb5', { action: 'submit' }).then(async (token) => {
        // Handle the form submission with the reCAPTCHA token
        const formData = {
          firstName: event.target['first-name'].value,
          lastName: event.target['last-name'].value,
          email: event.target.email.value,
          phone: event.target.phone.value,
          message: event.target.message.value,
          recaptchaToken: token, // Include the reCAPTCHA token
        };

        try {
          const response = await fetch('/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });

          if (response.ok) {
            setFeedbackMessage('Thank you, your message has been sent successfully.');
            event.target.reset();
          } else {
            setFeedbackMessage('Failed to send email. Please try again later.');
          }
        } catch (error) {
          console.error('Error:', error);
          setFeedbackMessage('Error sending email. Please try again later.');
        } finally {
          setIsSubmitting(false);
        }
      });
    });
  };

  return (
    <section className="contact" id="contact">
      <div className="container">
        <section className="contact-header">
          <h2 className="contact-head">Let's Work Together</h2>
          <div className="contact-form">
            <p>Your vision and our expertise; it's the perfect combination to create something extraordinary...</p>
            <form onSubmit={handleSubmit}>
              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="first-name">First Name</label>
                  <input type="text" id="first-name" placeholder="First" required/>
                </div>
                <div className="input-group">
                  <label htmlFor="last-name">Last Name</label>
                  <input type="text" id="last-name" placeholder="Last" required/>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="phone">Phone</label>
                <input type="tel" id="phone" placeholder="XXX-XXX-XXXX" />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" placeholder="email@example.com" required/>
              </div>
              <div className="input-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" placeholder="Your message here..." required/>
              </div>
             
              <button type="submit" className="send-button" disabled={isSubmitting}>Send</button>
            </form>
            {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
          </div>
        </section>
      </div>
      {/* Other content goes here */}
    </section>
  );
}

export default Contact;
