import React, { useEffect } from 'react';
import LogoAnimation from './LogoAnimation'; // Adjust the path as needed
import { gsap } from 'gsap';

function Hero() {
// Define the array of image paths
  const images = [
    { src: '../assets/BOING.jpg', alt: 'clock image', className: 'image-0' },
    { src: '../assets/Cards-Mock-together.jpg', alt: 'Movement as Medicine business cards', className: 'image-1' },
    { src: '../assets/FAQ-slider.jpg', alt: 'FAQ slider image', className: 'image-2' },
    { src: '../assets/free-shipping.png', alt: 'Free Shipping Social image', className: 'image-3' },
    { src: '../assets/H&S Phase 3_resized.jpg', alt: 'Dofasco Health & Saftey Poster', className: 'image-4' },
    { src: '../assets/HamiltonHomePage.jpg', alt: 'City of Hamilton Web', className: 'image-5' },
    { src: '../assets/intermetco-cover.jpg', alt: 'Intermetco Corporate History', className: 'image-6' },
    { src: '../assets/Movement---Movement-as-Medicine---movementasmedicine.ca-crop.jpg', alt: 'Movement as Medicine Web', className: 'image-7' },
    { src: '../assets/Shades3.jpg', alt: 'Dofasco Recruitment Brochure', className: 'image-8'  },
    { src: '../assets/Sign_Shot_7Final.jpg', alt: 'Sign Image for Internation Truck and Engine corp', className: 'image-9' },
    { src: '../assets/WHOLE-HOUSE.jpg', alt: 'House illustration for Union Energy', className: 'image-10' },
    { src: '../assets/Raleigh Stuff  2 spread.jpg', alt: 'Spread from Raleigh Bike Catalog', className: 'image-11' }
  ];


  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, yoyo: true });
    
    tl.to(".arrow", {
      duration: 0.5,
      ease: "power1.inOut",
      y: -5
    });

    gsap.from('.hero-head, .cta-button', {
      delay: 1, // 1 second delay to allow the logo to appear first
      duration: 1,
      opacity: 0,
      y: 20,
      ease: 'power3.out',
      stagger: 0.2,
    });

    // Animate the image placeholders
    gsap.fromTo('.image-0, .image-1,.image-2,.image-3,.image-4,.image-5,.image-6,.image-7,.image-8,.image-9,.image-10,.image-11', {
      x: '110vw', // Start from beyond the right edge of the viewport
    }, {
      duration: 2, // Make the duration longer for a slower effect
      x: 0, // End at their normal positions
      ease: 'power2.inOut', // Use a different easing function for a smoother effect
      stagger: {
        amount: 0.5, // Stagger the start times for a cascading effect
        from: 'start', // Start the stagger from the beginning of the set of elements
      },
    });

     // Function to handle hover animations
     const handleHover = (e) => {
      gsap.to(e.target, {
          scale: 2, // Scale up the image
          zIndex: 100, // Move the image to the front
          boxShadow: '0 0 3cqw rgba(0, 0, 0, 0.5)', // Add shadow glow effect
          ease: 'power1.out',
          duration: 0.3
      });
  };

  // Function to handle hover out animations
  const handleHoverOut = (e) => {
      gsap.to(e.target, {
          scale: 1, // Reset the scale
          zIndex: 1, // Reset the z-index
          boxShadow: 'none', // Remove shadow glow effect
          ease: 'power1.out',
          duration: 0.3
      });
  };

  // Attach hover animations to each image
  images.forEach((image, index) => {
      let imgElement = document.querySelector(`.${image.className}`);
      imgElement.addEventListener('mouseenter', handleHover);
      imgElement.addEventListener('mouseleave', handleHoverOut);
  });

  // Cleanup event listeners on component unmount
  return () => {
      images.forEach((image, index) => {
          let imgElement = document.querySelector(`.${image.className}`);
          imgElement.removeEventListener('mouseenter', handleHover);
          imgElement.removeEventListener('mouseleave', handleHoverOut);
      });
  };

  }, []);

  return (
    <section className="hero" id="hero">
      <div className="container">
        
        <div className="hero-header">
          <h1 className="hero-head">
            Want to Add <span>a New Dimension</span><span>to Your 
            Brand?</span>
              <a href="#contact"><button className="cta-button">LET'S WORK TOGETHER</button></a>
          </h1>
        </div>
        <div className="hero-content">
      <div id="logoAnimation-container">
        <LogoAnimation />
      </div>
      <div className="portfolio-images">
      {images.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
              className={image.className} // Use the predefined class name
            />
          ))}
      </div>
    </div>
    <div className="scroll-down">
        Scroll down
        <div className="arrow"></div>
      </div>
      </div>
    </section>
  );
}

export default Hero;