import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Work = () => {
  const [refCorpText, isVisibleCorpText] = useIntersectionObserver();
  const [refCorpImages, isVisibleCorpImages] = useIntersectionObserver();
  const [refH2CorpDesign, isVisibleH2CorpDesign] = useIntersectionObserver();
  const [refRetText, isVisibleRetText] = useIntersectionObserver();
  const [refRetImages, isVisibleRetImages] = useIntersectionObserver();
  const [refH2RetDesign, isVisibleH2RetDesign] = useIntersectionObserver();
  const [refWebText, isVisibleWebText] = useIntersectionObserver();
  const [refWebImages, isVisibleWebImages] = useIntersectionObserver();
  const [refH2WebDesign, isVisibleH2WebDesign] = useIntersectionObserver();
  const [refLogText, isVisibleLogText] = useIntersectionObserver();
  const [refLogImages, isVisibleLogImages] = useIntersectionObserver();
  const [refH2LogDesign, isVisibleH2LogDesign] = useIntersectionObserver();
  const [refPacText, isVisiblePacText] = useIntersectionObserver();
  const [refPacImages, isVisiblePacImages] = useIntersectionObserver();
  const [refH2PacDesign, isVisibleH2PacDesign] = useIntersectionObserver();
  const [refIllText, isVisibleIllText] = useIntersectionObserver();
  const [refIllImages, isVisibleIllImages] = useIntersectionObserver();
  const [refH2IllDesign, isVisibleH2IllDesign] = useIntersectionObserver();
  const [refMotText, isVisibleMotText] = useIntersectionObserver();
  const [refMotImages, isVisibleMotImages] = useIntersectionObserver();
  const [refH2MotDesign, isVisibleH2MotDesign] = useIntersectionObserver();
  // const [refH2CorpDesign, isVisibleH2CorpDesign] = useIntersectionObserver({
  //   root: null, // Setting to the viewport
  //   rootMargin: '-10% 0px -40% 0px', // Negative values shrink the root's bounding box
  //   threshold: 0.2 // Trigger when 10% of the element is visible
  // });
  
// State for the reel lightbox
const [isReelModalOpen, setIsReelModalOpen] = useState(false);

// State for the portfolio lightbox
const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
const [portfolioItems, setPortfolioItems] = useState([
  // Add your portfolio items here
  // Example: { id: 1, src: 'path/to/image1.jpg', title: 'Title 1', description: 'Description 1', category: 'Category 1', caseStudyLink: 'link/to/case-study-1' },
  // ...
  { id: 1, 
    src: '/assets/Shades3.jpg', 
    title: 'Dofasco Recruitment Brochurere', 
    description: 'Client: Dofasco<br />Agency: Kelley Advertising', 
    category: 'Corporate Design', 
    tools: 'Photoshop, Illustrator, Indesign', 
    caseStudyLink: '',
    type: 'image' },
    { id: 2, 
      src: '/assets/intermetco-cov-spread-vert.png', 
      title: 'Intermetco Corporate History', 
      description: 'Client: Intermetco<br /><br />Awards: Gold Ace Award (Corporate Design) - Hamilton Sales and Marketing Association', 
      category: 'Corporate Design', 
      tools: 'Photoshop, Illustrator, Indesign', 
      caseStudyLink: '',
      type: 'image' },
      { id: 3, 
        src: '/assets/csa-spread-cov-vert.png', 
        title: 'CSA Annual Report', 
        description: 'Client: CSA<br />Agency: Kelley Advertising', 
        category: 'Corporate Design', 
        tools: 'Photoshop, Illustrator, Indesign', 
        caseStudyLink: '',
        type: 'image' },
        { id: 4, 
          src: '/assets/dof-health-saftey.png', 
          title: 'Dofasco Health & Saftey Posters', 
          description: 'Client: Dofasco<br />Agency: Kelley Advertising', 
          category: 'Corporate Design', 
          tools: 'Photoshop, Illustrator', 
          caseStudyLink: '',
          type: 'image' },
          { id: 5, 
            src: '/assets/ret-sliders.png', 
            title: 'Marketing Website Sliders', 
            description: 'Client: McMaster Campus Store', 
            category: 'Business to Consumer', 
            tools: 'Photoshop, Illustrator', 
            caseStudyLink: '',
            type: 'image' },
            { id: 6, 
              src: '/assets/Social-Insta.mp4', 
              title: 'Social Media Animation', 
              description: 'Client: McMaster Campus Store', 
              category: 'Business to Consumer', 
              tools: 'Photoshop, Illustrator, After Effects', 
              caseStudyLink: '',
              type: 'video' },
              { id: 7, 
                src: '/assets/ret-raleigh-vert.png', 
                title: 'Raleigh Canada Bicycle Catalog', 
                description: 'Client: Raleigh Canada', 
                category: 'Business to Consumer', 
                tools: 'Photoshop, Illustrator, Indesign', 
                caseStudyLink: '',
                type: 'image' },
                { id: 8, 
                  src: '/assets/city-web.png', 
                  title: 'City of Hamilton Web Template System', 
                  description: `A template system designed to work with the City of Hamilton's government content management system (GCMS)<br/><br/>Client: City of Hamilton<br />Agency: JAN Kelley Marketing`, 
                  category: 'UX/UI Design & Coding', 
                  tools: 'Photoshop, Illustrator, Dreamweaver', 
                  caseStudyLink: '',
                  type: 'image' },
                  { id: 9, 
                    src: '/assets/movement-web.png', 
                    title: 'Movement as Medicine Website', 
                    description: 'Client: Movement as Medicine', 
                    category: 'UX/UI Design & Coding', 
                    tools: 'Photoshop, Illustrator, VS Code, Drupal, PHP, Twig Templates', 
                    caseStudyLink: '',
                    type: 'image' },
                    { id: 10, 
                      src: '/assets/CS-pages.png', 
                      title: 'McMaster Campus Store Website', 
                      description: `The Campus Store website, built on a legacy PerlScript framework, ties directly into their POS (Point of Sale) and Retail ERP (Enterprise Resource Planning) software. Using a sandboxed CMS, JSON:API, Express middleware and Node.js - static content is updated dynamically.<br /><br />Client: Campus Store`, 
                      category: 'UX/UI Design & Coding', 
                      tools: 'Photoshop, Illustrator, VS Code, Drupal, JSON:API, Express, Node.js', 
                      caseStudyLink: '',
                      type: 'image' },
                      { id: 11, 
                        src: '/assets/3dgrill-log-alone.png', 
                        title: '3DGrill Branding', 
                        description: `Brand developement for a family of barbecue products<br /><br />Client: Willow Wells`, 
                        category: 'Identity & Branding', 
                        tools: 'Illustrator', 
                        caseStudyLink: '',
                        type: 'image' },
                        { id: 12, 
                          src: '/assets/movement-log-alone.png', 
                          title: 'Movement as Medicine Branding', 
                          description: `Movement as Medicine is a Somatic Movement studio promoting healing through movement and healthy living<br /><br />Client: Movement as Medicine`, 
                          category: 'Identity & Branding', 
                          tools: 'Illustrator', 
                          caseStudyLink: '',
                          type: 'image' },
                          { id: 13, 
                            src: '/assets/shadowpress-log-alone.png', 
                            title: 'Shadowpress Publishing', 
                            description: `Client: Shadowpress`, 
                            category: 'Identity & Branding', 
                            tools: 'Illustrator', 
                            caseStudyLink: '',
                            type: 'image' },
                            { id: 14, 
                              src: '/assets/maadookii-log-alone.png', 
                              title: 'Maadookii Seniors Centre', 
                              description: `Client: Chippewas of Nawash - Maadookii`, 
                              category: 'Identity & Branding', 
                              tools: 'Illustrator', 
                              caseStudyLink: '',
                              type: 'image' },
                              { id: 15, 
                                src: '/assets/homesteel-log-alone.png', 
                                title: 'Dofasco Homesteel', 
                                description: `Client: Dofasco<br />Agency: Kelley Advertising`, 
                                category: 'Identity & Branding', 
                                tools: 'Illustrator', 
                                caseStudyLink: '',
                                type: 'image' },
                                { id: 16, 
                                  src: '/assets/circle-crest-lapel-pack.png', 
                                  title: 'Circle Crest Lapel Pin Hanger', 
                                  description: `A packaging system for a series of lapel pins<br /><br />Client: McMaster Campus Store`, 
                                  category: 'Packaging', 
                                  tools: 'Illustrator', 
                                  caseStudyLink: '',
                                  type: 'image' },
                                  { id: 17, 
                                    src: '/assets/3dgrill-packs.png', 
                                    title: '3DGrill Packaging', 
                                    description: `Packaging system for the 3DGrill line of barbecue products<br /><br />Client: Willow Wells`, 
                                    category: 'Packaging', 
                                    tools: 'Photoshop, Illustrator, Cinema 4D', 
                                    caseStudyLink: '',
                                    type: 'image' },
                                    { id: 18, 
                                      src: '/assets/compliments-packs.png', 
                                      title: 'Compliments Packaging', 
                                      description: `Client: Sobeys<br />Agency: JAN Kelley Marketing`, 
                                      category: 'Packaging', 
                                      tools: 'Photoshop, Illustrator', 
                                      caseStudyLink: '',
                                      type: 'image' },
                                      { id: 19, 
                                        src: '/assets/green-earth-packs.png', 
                                        title: 'Green Earth Packaging', 
                                        description: `Client: Sure-Gro<br />Agency: Kelley Advertising`, 
                                        category: 'Packaging', 
                                        tools: 'Photoshop, Illustrator', 
                                        caseStudyLink: '',
                                        type: 'image' },
                                        { id: 20, 
                                          src: '/assets/enersmart-ill-alone.png', 
                                          title: 'Enersmart Illustrations', 
                                          description: `Series of illustrations for an energy savings program<br /><br />Client: Union Energy<br />Agency: Kelley Advertising`, 
                                          category: 'Photoshop & Illustration', 
                                          tools: 'Photoshop, Illustrator', 
                                          caseStudyLink: '',
                                          type: 'image' },
                                          { id: 21, 
                                            src: '/assets/unity-ill-alone.png', 
                                            title: 'Unity RV', 
                                            description: `Illustration of a Mercedes Unity RV<br /><br />Client: Donna Shapiro`, 
                                            category: 'Photoshop & Illustration', 
                                            tools: 'Illustrator', 
                                            caseStudyLink: '',
                                            type: 'image' },
                                            { id: 22, 
                                              src: '/assets/magnesium-hydroxide-ill-alone.jpg', 
                                              title: 'Magnesium Hydroxide', 
                                              description: `Client: Dow<br />Agency: Kelley Advertising<br /><br />Awards: Silver (illustration) - Canadian Computer Graphics Association`, 
                                              category: 'Photoshop & Illustration', 
                                              tools: 'Photoshop, Illustrator', 
                                              caseStudyLink: '',
                                              type: 'image' },
                                              { id: 23, 
                                                src: '/assets/union-clock-ill-alone.jpg', 
                                                title: 'Time to Change Your Furnace', 
                                                description: `Client: Union Gas<br />Agency: Kelley Advertising<br /><br />Awards: Silver Ace Award (imaging) - Hamilton Sales and Marketing Association`, 
                                                category: 'Photoshop & Illustration', 
                                                tools: 'Photoshop', 
                                                caseStudyLink: '',
                                                type: 'image' },
                                                { id: 24, 
                                                  src: '/assets/Int-Illustration-render.mp4', 
                                                  title: 'Winter Tune Up', 
                                                  description: `Photoshop template illustration. Designed so that the dealers location and service icons can easily be added to the image through smart objects<br /><br />Client: International Truck and Engine Corp<br />Agency: JAN Kelley Marketing`, 
                                                  category: 'Photoshop & Illustration', 
                                                  tools: 'Photoshop', 
                                                  caseStudyLink: '',
                                                  type: 'video' }

]);

const [currentPortfolioItem, setCurrentPortfolioItem] = useState(null);


// Old reel lightbox code
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // Functions for handling the reel lightbox
  const openReelModal = () => {
    setIsReelModalOpen(true);
  };

  const closeReelModal = () => {
    setIsReelModalOpen(false);
  };

  // Functions for handling the portfolio lightbox
  // const openPortfolioItem = (item) => {
  //   setCurrentPortfolioItem(item);
  //   setIsPortfolioModalOpen(true);
  // };

  const openPortfolioItem = (item = portfolioItems[0]) => {
    setCurrentPortfolioItem(item);
    setIsPortfolioModalOpen(true);
  };

  const navigatePortfolio = (direction) => {
    if (!currentPortfolioItem) return;
    const currentIndex = portfolioItems.findIndex((item) => item.id === currentPortfolioItem.id);
    let newIndex = currentIndex + direction;
    if (newIndex < 0) newIndex = portfolioItems.length - 1;
    if (newIndex >= portfolioItems.length) newIndex = 0;
    setCurrentPortfolioItem(portfolioItems[newIndex]);
  };

  


  // const handleHover = (e) => {
  //   let targetElement = e.target.closest('.image-container') || e.target;
  //   let scaleValue = targetElement.classList.contains('image-container') ? 2 : 2; // Adjust scale as needed
  //   let zIndexValue = targetElement.classList.contains('image-container') ? 100 : 100;
  
  //   gsap.to(targetElement, {
  //     scale: scaleValue,
  //     zIndex: zIndexValue,
  //     filter: "drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5))",
  //     ease: "power1.out",
  //     duration: 0.3,
  //     delay: 0.2 // Delay in seconds
  //   });
  // };
  
  // const handleHoverOut = (e) => {
  //   let targetElement = e.target.closest('.image-container') || e.target;
  
  //   gsap.to(targetElement, {
  //     scale: 1,
  //     zIndex: 1,
  //     filter: "none",
  //     ease: "power1.out",
  //     duration: 0.3,
  //     delay: 0.2 // Delay in seconds
  //   });
  // };

  const createMarkup = (htmlContent) => {
    return {__html: htmlContent};
  };
  
  const handleHover = (e) => {
    let targetElement = e.target.closest('.image-container') || e.target;
    let scaleValue = targetElement.classList.contains('image-container') ? 1.5 : 1.5;

    // Calculate center of viewport
    let viewportCenter = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

    // Determine the element to be translated (image or container)
    let translateElement = targetElement.classList.contains('image-container') ? targetElement : e.target;
    let rect = translateElement.getBoundingClientRect();

    // Calculate the distance from element center to viewport center
    let elementCenter = { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
    let translateX = (viewportCenter.x - elementCenter.x) * 0.3; // Adjust the factor for subtleness
    let translateY = (viewportCenter.y - elementCenter.y) * 0.3; // Adjust the factor for subtleness

    // Combined GSAP animation for scaling and translating
    gsap.to(targetElement, {
        scale: scaleValue,
        zIndex: 100,
        filter: "drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5))",
        x: `+=${translateX}px`,
        y: `+=${translateY}px`,
        ease: "power1.out",
        duration: 0.3,
        delay: 0.2
    });
};

const handleHoverOut = (e) => {
    let targetElement = e.target.closest('.image-container') || e.target;

    // Combined GSAP animation for resetting scale and position
    gsap.to(targetElement, {
        scale: 1,
        zIndex: 1,
        filter: "none",
        x: 0,
        y: 0,
        ease: "power1.out",
        duration: 0.3,
        delay: 0.2
    });
};

  

  useEffect(() => {
    // Attach to image containers
    const imageContainers = document.querySelectorAll('.image-container');
    imageContainers.forEach(container => {
      container.addEventListener('mouseenter', handleHover);
      container.addEventListener('mouseleave', handleHoverOut);
    });
  
    // Attach to individual images (excluding those in image containers)
    const individualImages = document.querySelectorAll('.work-image:not(.image-container .work-image)');
    individualImages.forEach(img => {
      img.addEventListener('mouseenter', handleHover);
      img.addEventListener('mouseleave', handleHoverOut);
    });
    
  
    // Cleanup event listeners on component unmount
    return () => {
      imageContainers.forEach(container => {
        container.removeEventListener('mouseenter', handleHover);
        container.removeEventListener('mouseleave', handleHoverOut);
      });
      individualImages.forEach(img => {
        img.removeEventListener('mouseenter', handleHover);
        img.removeEventListener('mouseleave', handleHoverOut);
      });
    };

    
  }, []);

  return (
  <section className="work-section" id="work">
    
      <section className="design" id="design">
        <div className="corp-design">
        <div className="container">
         
        <h2 ref={refH2CorpDesign} className={` ${isVisibleH2CorpDesign ? 'fade-up' : ''}`}>
              Corporate Design
              </h2>
            <div className="corp-wrap">
            <div ref={refCorpText} className={`corp-text ${isVisibleCorpText ? 'slide-in-left' : ''}`}>
            <p>
            This design approach focuses on corporate identity and crafting visual elements that mirror a company's mission, vision, and values. Specialize in developing visual consistency and designing impactful marketing materials to foster effective brand communication.
            </p>
            <button className="cta-book" onClick={() => openPortfolioItem(portfolioItems[0])}>View Portfolio</button>
          </div>
          <div ref={refCorpImages} className={`corp-images ${isVisibleCorpImages ? 'slide-in-right' : ''}`}>
             <div className="image1"><img src="/assets/Shades3.jpg" alt="Dofasco Recruitment Brochure Cover" className="work-image" /></div>
             <div className="image2"><img src="/assets/intermetco-cov-spread.png" alt="Intermetco Corporate History" className="work-image" /></div>
             <div className="image3"><img src="/assets/csa-spread-cov.png" alt="CSA Annual Report" className="work-image" /></div>
          </div>
          </div>
        </div>
        </div>
        <div className="ret-design">
        <div className="container">
         
            <h2 ref={refH2RetDesign} className={` ${isVisibleH2RetDesign ? 'fade-up' : ''}`}>Business to Consumer</h2>
            <div className="ret-wrap">
            <div ref={refRetImages} className={`ret-images ${isVisibleRetImages ? 'slide-in-left' : ''}`}>
             <div className="image1"><img src="/assets/ret-sliders.png" alt="Retail Website Sliders"  className="work-image" /></div>
             <div className="image2"><img src="/assets/ret-social.png" alt="Retail Social Media" className="work-image" /></div>
             <div className="image3"><img src="/assets/ret-raleigh.png" alt="Raleigh Catalog" className="work-image" /></div>
          </div>
            <div ref={refRetText} className={`ret-text ${isVisibleRetText ? 'slide-in-right' : ''}`}>
            <p>
            A deep understanding of the consumer experience showcases expertise in retail design. The primary goal is to create designs that align with a brand's identity to foster engagement and drive sales. Utilizing design principles tailored to the target audience aids retailers in enhancing in-store experiences and boosting customer satisfaction.
            </p>
            <button className="cta-book" onClick={() => openPortfolioItem(portfolioItems[4])}>View Portfolio</button>
          </div>
          
          </div>
        </div>
        </div>
        <div className="web-design">
        <div className="container">
         
            <h2 ref={refH2WebDesign} className={` ${isVisibleH2WebDesign ? 'fade-up' : ''}`}>UX/UI Design & Coding</h2>
            <div className="web-wrap">
            <div ref={refWebText} className={`web-text ${isVisibleWebText ? 'slide-in-left' : ''}`}>
            <p>
            Web design skills include a holistic understanding of user experience centred on developing intuitive and engaging interfaces. Websites crafted to offer seamless experiences across devices feature responsive design, straightforward navigation, and visually appealing layouts. Attention to detail and a commitment to the latest web design trends and technologies ensure the creation of modern, effective websites for reaching online audiences.
            </p>
            <button className="cta-book" onClick={() => openPortfolioItem(portfolioItems[7])}>View Portfolio</button>
          </div>
          <div ref={refWebImages} className={`web-images ${isVisibleWebImages ? 'slide-in-right' : ''}`}>
             <div className="image1"><img src="/assets/city-web.png" alt="City of Hamilton Template System" className="work-image" /></div>
             <div className="image2"><img src="/assets/movement-web.png" alt="Movement as Medicine Website" className="work-image" /></div>
             <div className="image3"><img src="/assets/code-icon.svg" alt="Coding Icon" className="" /></div>
          </div>
          </div>
        </div>
        </div>
        <div className="log-design">
        <div className="container">
         
            <h2 ref={refH2LogDesign} className={` ${isVisibleH2LogDesign ? 'fade-up' : ''}`}>Identity & Branding</h2>
            <div className="log-wrap">
            <div ref={refLogImages} className={`log-images ${isVisibleLogImages ? 'slide-in-left' : ''}`}>
             <div className="image1 image-container"><img src="/assets/3d-grill-log.png" alt="3D Grill Branding" className="work-image" /></div>
             <div className="image2 image-container"><img src="/assets/movement-log.png" alt="Movement as Medicine Brand" className="work-image" /></div>
             <div className="image3 image-container"><img src="/assets/shadowpress-log.png" alt="Shadowpress Publishing" className="work-image" /></div>
             <div className="image4 image-container"><img src="/assets/maadookii-log.png" alt="Maadookii Seniors Centre Logo" className="work-image" /></div>
             <div className="image5 image-container"><img src="/assets/homesteel-log.png" alt="Dofasco Home Steel Logo"className="work-image" /></div>
          </div>
            <div ref={refLogText} className={`log-text ${isVisibleLogText ? 'slide-in-right' : ''}`}>
            <p>
            A well-designed logo forms the cornerstone of a strong brand identity. Creative intuition with a deep understanding of brand essence leads to visually appealing logos that convey a brand's core values and personality. Comprehensive branding solutions are integral to providing businesses with cohesive and memorable identities.
            </p>
            <button className="cta-book" onClick={() => openPortfolioItem(portfolioItems[10])}>View Portfolio</button>
          </div>
          
          </div>
        </div>
        </div>
        <div className="pac-design">
        <div className="container">
         
            <h2 ref={refH2PacDesign} className={` ${isVisibleH2PacDesign ? 'fade-up' : ''}`}>Package Design</h2>
            <div className="pac-wrap">
            <div ref={refPacText} className={`pac-text ${isVisiblePacText ? 'slide-in-left' : ''}`}>
            <p>
            Packaging design combines aesthetics with functionality to create packaging that stands out on the shelves while maintaining product integrity. Through the strategic use of colour, typography, and materials,  packaging solutions should reflect the product's essence and create a memorable unboxing experience for consumers.
            </p>
            <button className="cta-book" onClick={() => openPortfolioItem(portfolioItems[15])}>View Portfolio</button>
          </div>
          <div ref={refPacImages} className={`pac-images ${isVisiblePacImages ? 'slide-in-right' : ''}`}>
             <div className="image1"><img src="/assets/lapel-pac.png" alt="Circle Crest Lapel Pin Package" className="work-image" /></div>
             <div className="image2"><img src="/assets/grill-pac.jpg" alt="3D Grill packaging system" className="work-image" /></div>
             <div className="image3"><img src="/assets/compliments-pac.png" alt="Compliments Packaging" className="work-image" /></div>
             <div className="image4"><img src="/assets/green-earth-pac.png" alt="Green Earth Packaging" className="work-image"  /></div>
          </div>
          </div>
        </div>
        </div>
      </section>
      <section className="illustration" id="illustration">
      <div className="ill-pho">
        <div className="container">
         
            <h2 ref={refH2IllDesign} className={` ${isVisibleH2IllDesign ? 'fade-up' : ''}`}>Photoshop & Illustration</h2>
            <div className="ill-wrap">
            <div ref={refIllImages} className={`ill-images ${isVisibleIllImages ? 'slide-in-left' : ''}`}>
             <div className="image1"><img src="/assets/enersmart-ill.png" alt="Illustration for the Enersmart Energy Savings program" className="work-image" /></div>
             <div className="image2"><img src="/assets/unity-ill.png" alt="Illustration of a Unity RV" className="work-image" /></div>
             <div className="image3"><img src="/assets/dow-ill.jpg" alt="Illustration for Dow Magnesium Hydroxide ad" className="work-image" /></div>
             <div className="image4"><img src="/assets/union-clock-ill.jpg" alt="Photo Imaging for Union Gas -  Time to Change Your Furnace" className="work-image" /></div>
             <div className="image5"><img src="/assets/int-ill.png" alt="Illustration Template for Internation Truck and Engine Corp Dealer Service Centers" className="work-image" /></div>
          </div>
            <div ref={refIllText} className={`ill-text ${isVisibleIllText ? 'slide-in-right' : ''}`}>
            <p>
            A keen eye for detail is essential for creating captivating illustrations and photo imaging. Blending traditional techniques with modern tools can bring concepts to life. Photo imaging includes retouching and manipulation, transforming ordinary images into extraordinary visuals.
            </p>
            <button className="cta-book" onClick={() => openPortfolioItem(portfolioItems[19])}>View Portfolio</button>
          </div>
          
          </div>
        </div>
        </div>
        </section>
        <section className="motion" id="motion-design">
        <div className="mot-design">
        <div className="container">
         
            <h2 ref={refH2MotDesign} className={` ${isVisibleH2MotDesign ? 'fade-up' : ''}`}>Motion Graphics & Video</h2>
            <div className="mot-wrap">
            <div ref={refMotText} className={`mot-text ${isVisibleMotText ? 'slide-in-left' : ''}`}>
            <p>
            A flair for creativity and technical mastery catalyzes motion design, transforming abstract concepts into dynamic visual experiences. Video editing and compositing mastery enable crafting cohesive narratives, blending various visual elements with finesse and precision. 3D animation creates engaging and visually appealing animations that add depth and dimension to projects. Motion design, video editing, and 3D animation combine to deliver content that resonates with audiences and meets my clients' objectives.
            </p>
            <button className="cta-book" onClick={openReelModal}>View Reel</button>
          </div>
          <div ref={refMotImages} className={`mot-images ${isVisibleMotImages ? 'slide-in-right' : ''}`}>
             <div className="image1"><img src="/assets/greens-plus-mot.png" alt="Greens+ Product Showcase Video" className="" /></div>
             <div className="image2"><img src="/assets/beth-jacob-mot.png" alt="Beth Jacob 100 Year Celebration Presentation Video" className="" /></div>
             <div className="image3"><img src="/assets/part-smart-mot.png" alt="International Truck and Engine Corp - Dealer Parts Promo Video" className="" /></div>
          </div>
          </div>
        </div>
        </div>
        </section>
        {/* {portfolioItems.map((item) => (
        <img 
          key={item.id} 
          src={item.src} 
          alt={item.title} 
          onClick={() => openPortfolioItem(item)} 
          className="portfolio-image"
        />
      ))} */}
    
        {/* {isModalOpen && (
        <div className="lightbox-modal" onClick={closeModal}>
          <div className="video-container" onClick={(e) => e.stopPropagation()}>
            <button onClick={() => setIsModalOpen(false)} className="close-button">X</button>
            <video controls autoPlay>
              <source src="/assets/Reel.Comp4.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      )} */}

       {/* Reel Lightbox */}
       {isReelModalOpen && (
        <div className="lightbox-modal" onClick={closeReelModal}>
          <div className="video-container" onClick={(e) => e.stopPropagation()}>
            <button onClick={closeReelModal} className="close-button">X</button>
            <video controls autoPlay>
              <source src="/assets/Reel.Comp4.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      )}

      {/* Portfolio Lightbox */}
      {/* {isPortfolioModalOpen && (
        <div className="lightbox-modal" onClick={() => setIsPortfolioModalOpen(false)}>
          <div className="portfolio-lightbox" onClick={(e) => e.stopPropagation()}>
            <button onClick={() => setIsPortfolioModalOpen(false)} className="close-button">X</button>
<img src={currentPortfolioItem?.src} alt={currentPortfolioItem?.title} />
<p>{currentPortfolioItem?.description}</p>
<p>{currentPortfolioItem?.category}</p>
{currentPortfolioItem?.caseStudyLink && <a href={currentPortfolioItem.caseStudyLink}>Case Study</a>}
<button onClick={() => navigatePortfolio(-1)}>Previous</button>
<button onClick={() => navigatePortfolio(1)}>Next</button>
</div>
</div>
)} */}

 {/* Portfolio Lightbox */}
{isPortfolioModalOpen && (
    <div className="lightbox-modal" onClick={() =>setIsPortfolioModalOpen(false)}>
    <div className="portfolio-lightbox" onClick={(e) => e.stopPropagation()}>
        <button onClick={() => setIsPortfolioModalOpen(false)} className="close-button">X</button>
        
        <div className="portfolio-content">
            <div className="portfolio-info">
                <h3>{currentPortfolioItem?.title}</h3>
                {/* Using dangerouslySetInnerHTML to render HTML content */}
                <p dangerouslySetInnerHTML={createMarkup(currentPortfolioItem?.description)}></p>
                <p>Tools: {currentPortfolioItem?.tools}</p>
                {currentPortfolioItem?.caseStudyLink && <a href={currentPortfolioItem.caseStudyLink}>Case Study</a>}
</div>
<div className="portfolio-image">
  {currentPortfolioItem?.type === 'image' ? (
    <img src={currentPortfolioItem?.src} alt={currentPortfolioItem?.title} />
  ) : (
    <video src={currentPortfolioItem?.src} autoPlay loop muted>
      <source src={currentPortfolioItem?.src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )}
</div>
</div>
<div className="directional_nav">
<div className="previous_btn" title="Previous" onClick={() => navigatePortfolio(-1)}>
    <svg width="65px" height="65px" viewBox="-11 -11.5 65 66">
        {/* SVG paths */}
        <path fill="#fff" d="M-10.5,22.118C-10.5,4.132,4.133-10.5,22.118-10.5S54.736,4.132,54.736,22.118
            c0,17.985-14.633,32.618-32.618,32.618S-10.5,40.103-10.5,22.118z M-8.288,22.118c0,16.766,13.639,30.406,30.406,30.406 c16.765,0,30.405-13.641,30.405-30.406c0-16.766-13.641-30.406-30.405-30.406C5.35-8.288-8.288,5.352-8.288,22.118z"/>
        <path fill="#fff" d="M25.43,33.243L14.628,22.429c-0.433-0.432-0.433-1.132,0-1.564L25.43,10.051c0.432-0.432,1.132-0.432,1.563,0
            c0.431,0.431,0.431,1.132,0,1.564L16.972,21.647l10.021,10.035c0.432,0.433,0.432,1.134,0,1.564
            c-0.215,0.218-0.498,0.323-0.78,0.323C25.929,33.569,25.646,33.464,25.43,33.243z"/>
            </svg>
            
            </div>
            <div className="next_btn" title="Next" onClick={() => navigatePortfolio(1)}>
                <svg width="65px" height="65px" viewBox="-11 -11.5 65 66">
                    {/* SVG paths */}
                    <path fill="#fff" d="M22.118,54.736C4.132,54.736-10.5,40.103-10.5,22.118C-10.5,4.132,4.132-10.5,22.118-10.5
                        c17.985,0,32.618,14.632,32.618,32.618C54.736,40.103,40.103,54.736,22.118,54.736z M22.118-8.288
                        c-16.765,0-30.406,13.64-30.406,30.406c0,16.766,13.641,30.406,30.406,30.406c16.768,0,30.406-13.641,30.406-30.406
                        C52.524,5.352,38.885-8.288,22.118-8.288z"/>
                    <path fill="#fff" d="M18.022,33.569c0.282,0-0.566-0.105-0.781-0.323c-0.432-0.431-0.432-1.132,0-1.564l10.022-10.035
                        L17.241,11.615c0.431-0.432-0.431-1.133,0-1.564c0.432-0.432,1.132-0.432,1.564,0l10.803,10.814c0.433,0.432,0.433,1.132,0,1.564
                        L18.805,33.243
            C18.59,33.464,18.306,33.569,18.022,33.569z"/>
            </svg>
            
              </div>
            </div>
    </div>
</div>
)}




      {/* Other footer content goes here */}
    </section>
  );
};

export default Work;
