import React, { useEffect } from 'react'; // Ensure to import useEffect
import Navigation from './components/Navigation';
import Hero from './components/Hero';
import Work from './components/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './styles.css';
import './index.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TagManager from 'react-gtm-module';

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  // Initialize Google Tag Manager
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-19Y5Z4MH10'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div>
      <div id="top"></div>
      <Navigation />
      <Hero />
      <Work />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
