import React, { useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { CSM } from './js/csm/CSM.js'; // Uncomment if you need to use CSM

function LogoAnimation() {
  useEffect(() => {
    console.log("Running useEffect");
    let scene, camera, renderer, controls;

    // Init function (trimmed down from your original code)
    function init() {
      scene = new THREE.Scene();
      // let container = document.getElementById('canvas');
      let container = document.getElementById('three-anim');
      // const width = container.clientWidth*3;//width worked, but going to switch with and height to try and make parent full width
      const height = container.clientHeight*2;
      const aspectRatio = 1 / 1; // Define your desired aspect ratio here
      // const height = width / aspectRatio; // Calculate height based on aspect ratio
      const width = height / aspectRatio; //switched to try and make parent full width
      renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      // renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setSize(width, height);
      container.appendChild(renderer.domElement);
      // ... rest of your initialization code ...
        // renderer.outputEncoding = THREE.sRGBEncoding;
        // renderer.toneMapping = THREE.sRGBToneMapping;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        camera = new THREE.PerspectiveCamera(
          60,
          width / height,
          0.1,
          80
        );
        camera.position.set(0, 350, 100);
        camera.lookAt(scene.position);
        camera.far = 100000;
        camera.updateProjectionMatrix();

        controls = new OrbitControls(camera, renderer.domElement);

        controls.autoRotate = true;
        controls.autoRotateSpeed = 0.5;
        controls.enableDamping = true;
        controls.target.set(0, 0, 0);
        controls.enableZoom = false; 
        controls.enablePan = false;

        controls.minPolarAngle = 1.35;
        controls.maxPolarAngle = 1.35;

        const ambientLight = new THREE.AmbientLight( 0xffffff, 0.5 );
				scene.add( ambientLight );
        
        // new RGBELoader()
        //   .setPath("/textures/")
        //   .load("/textures/cannon_4k.hdr", function (texture) {
        //     texture.mapping = THREE.EquirectangularReflectionMapping;

        //     // scene.background = texture;
        //     scene.environment = texture;
        //   });

        new RGBELoader()
  .setPath("/textures/")
  .load(
    "cannon_4k.hdr", 
    function (texture) { // onLoad
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture;
    },
    undefined, // onProgress (optional, can be undefined)
    function (error) { // onError
      console.error('An error occurred while loading the HDR texture:', error);
    }
  );

        
          let loader = new GLTFLoader();

          loader.load(
            "/models/FunkyDH.base.paths-2022-text6.gltf",
            function (gltf) {
              gltf.scene.traverse(function (node) {
              //   if (node.isMesh) {
              //     node.castShadow = true;
              //     node.receiveShadow = true;
              //   }
              });
  
              const fdh = gltf.scene.children.find(
                (child) => child.name === "funkyDH"
              );
              const fd_floor = gltf.scene.children.find(
                (child) => child.name === "floorDH"
              );
  
              // const fd_ref = gltf.scene.children.find(
              //   (child) => child.name === "funkyDHRef"
              // );
              // fdh.receiveShadow = true;
              // fdh.castShadow = true;
  
             
  
              let bb = new THREE.Box3();
              bb.setFromObject(fdh);
              bb.getCenter(controls.target);
              scene.add(fdh);
              scene.add(fd_floor);
              // scene.add(fd_ref);
              // dirlight.target = fdh;
              // light.target= fdh;
            },
            undefined,
            function (error) {
              console.error(error);
            }
          );

          scene.traverse(function(node){
   
          });
          
                  animate();
          
                  
          
                  window.addEventListener("resize", onWindowResize);
    }

    // Resize function
    // function onWindowResize() {
    //   camera.aspect = window.innerWidth / window.innerHeight;
    //   camera.updateProjectionMatrix();

    //   renderer.setSize(window.innerWidth, window.innerHeight);
    // }

    function onWindowResize() {
      // Get the dimensions of the container
      const container = document.getElementById('three-anim');
      const width = container.clientWidth*3;
      // const height = container.clientHeight;
      const aspectRatio = 1 / 1; // Ensure the same aspect ratio is used here
      const height = width / aspectRatio; // Recalculate height based on aspect ratio

    
      // Update camera aspect ratio and renderer size
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    }
    

    // Animate function
    function animate() {
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }

    // Initialize and animate
    init();
    animate();

    // Call resize function immediately to ensure correct setup
    onWindowResize();

    // Resize event listener
    window.addEventListener("resize", onWindowResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  return (
    <div id="three-container">
  <div id="canvas">
      <p className="logopre">multimedia</p>
      <div id="three-anim"></div>
    
      <h1 className="logo-text">dennis<span className="haradyn">haradyn</span></h1>
      <p className="logosub">designer</p>
  </div> {/* New container for the canvas */}
</div>

  );
}

export default LogoAnimation;
