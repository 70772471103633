import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import logo from '../haradyn.svg';

gsap.registerPlugin(ScrollTrigger);



const Navigation = () => {
  const linksRef = useRef([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    gsap.set('.underline', { width: 0 }); // Initialize the underline width

    linksRef.current.forEach((link, index) => {
      link.addEventListener('mouseenter', () => {
        gsap.to(link.querySelector('.underline'), { width: '100%', duration: 0.3 });
      });

      link.addEventListener('mouseleave', () => {
        gsap.to(link.querySelector('.underline'), { width: 0, duration: 0.3 });
      });

      // Link to scroll trigger
      ScrollTrigger.create({
        trigger: document.getElementById(link.getAttribute('href').substring(1)),
        start: 'top 30%',
        end: 'bottom 30%',
        onEnter: () => gsap.to(link.querySelector('.underline'), { width: '100%', duration: 0.3 }),
        onLeave: () => gsap.to(link.querySelector('.underline'), { width: 0, duration: 0.3 }),
        onEnterBack: () => gsap.to(link.querySelector('.underline'), { width: '100%', duration: 0.3 }),
        onLeaveBack: () => gsap.to(link.querySelector('.underline'), { width: 0, duration: 0.3 }),
      });
    });
  }, []);

  return (
    <nav className="navigation">
      <div className="container navigation-container">
      <div className="logo">
      <a href="#top">
        <img src={logo} alt="Haradyn logo" />
        </a>
        <p>dennis<span className="haradyn">haradyn</span></p>
     
      </div>

      <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className={`content-overlay ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>

          <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <a ref={el => linksRef.current[0] = el} href="#design" onClick={closeMenu}><span className="link-text">Design</span><span className="underline"></span></a>
          <a ref={el => linksRef.current[1] = el} href="#illustration" onClick={closeMenu}><span className="link-text">Illustration</span><span className="underline"></span></a>
          <a ref={el => linksRef.current[2] = el} href="#motion-design" onClick={closeMenu}><span className="link-text">Motion Design</span><span className="underline"></span></a>
          <a ref={el => linksRef.current[3] = el} href="#contact" onClick={closeMenu} className="mobile-work-together">
    <span className="link-text">Let's Work Together</span>
    <span className="underline"></span>
  </a>
          <a href="#contact"><button className="work-together" >Let's Work Together</button></a>
      </div>
      </div>
    </nav>
  );
};

export default Navigation;
