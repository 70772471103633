import React from 'react';

const Footer = () => (
  <footer className="footer">
    <p>© 2023 Dennis Haradyn</p>
    {/* Other footer content goes here */}
  </footer>
);

export default Footer;
